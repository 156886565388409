import { gql } from "@apollo/client";

export const HomePageQuery = gql`
  query {
    HomePage(id: "home-page") {
      backgroundImage {
        asset {
          _id
          _type
          _createdAt
          _updatedAt
          _rev
          _key
          originalFilename
          label
          title
          description
          altText
          sha1hash
          extension
          mimeType
          size
          assetId
          uploadId
          path
          url
          metadata {
            _key
            _type
            location {
              _key
              _type
              lat
              lng
              alt
            }
            dimensions {
              _key
              _type
              height
              width
              aspectRatio
            }
            palette {
              _key
              darkMuted {
                _key
                _type
                background
                foreground
                population
                title
              }
              lightVibrant {
                _key
                _type
                background
                foreground
                population
                title
              }
              darkVibrant {
                _key
                _type
                background
                foreground
                population
                title
              }
              vibrant {
                _key
                _type
                background
                foreground
                population
                title
              }
              dominant {
                _key
                _type
                background
                foreground
                population
                title
              }
              lightMuted {
                _key
                _type
                background
                foreground
                population
                title
              }
              muted {
                _key
                _type
                background
                foreground
                population
                title
              }
            }
            lqip
            blurHash
            hasAlpha
            isOpaque
          }
          source {
            _key
            _type
            name
            id
            url
          }
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
      }
    }
  }
`;

export const EventsPageQuery = gql`
  query {
    EventsPage(id: "events-page") {
      _type
      events {
        title
        location
        date
        textColor
        hoverTextColor
        tileBackground {
          image {
            asset {
              _id
              _type
              _createdAt
              _updatedAt
              _rev
              _key
              originalFilename
              label
              title
              description
              altText
              sha1hash
              extension
              mimeType
              size
              assetId
              uploadId
              path
              url
              metadata {
                _key
                _type
                location {
                  _key
                  _type
                  lat
                  lng
                  alt
                }
                dimensions {
                  _key
                  _type
                  height
                  width
                  aspectRatio
                }
                palette {
                  _key
                  darkMuted {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                  lightVibrant {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                  darkVibrant {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                  vibrant {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                  dominant {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                  lightMuted {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                  muted {
                    _key
                    _type
                    background
                    foreground
                    population
                    title
                  }
                }
                lqip
                blurHash
                hasAlpha
                isOpaque
              }
              source {
                _key
                _type
                name
                id
                url
              }
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
          }
          imageDescription
        }
      }
    }
  }
`;

export const SongsPageQuery = gql`
  query {
    SongsPage(id: "songs-page") {
      songs {
        created
        songFile {
          asset {
            url
          }
        }
        songName
      }
    }
  }
`;
