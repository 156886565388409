import { routes } from "routes";
import Link from "next/link";
import styled, { keyframes } from "styled-components";
import image from "../../../assets/needle.svg";
import { mediaQuery } from "utils/media-queries";
import { useQuery } from "@apollo/client";
import { HomePageQuery } from "graphql/queries";

const rotateAnimation = keyframes`
from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const CircleListItem = styled.li`
  position: absolute;
  list-style-type: none;
  color: #f2f3ae;
  font-weight: bold;
  font-size: 2.5em;

  &:nth-child(1) {
    top: 16%;
    right: 14%;
  }

  &:nth-child(2) {
    top: 30%;
    right: 5%;
  }
  &:nth-child(3) {
    top: 44%;
    right: 2%;
  }
  &:nth-child(4) {
    top: 58%;
    right: 5%;
  }
  &:nth-child(5) {
    top: 72%;
    right: 14%;
  }
`;

const MiddleDot = styled.div`
  position: absolute;
  display: inline-block;
  background-color: black;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -5px;

  ::before {
    position: absolute;
    content: "";
    border: 1px solid #d2d7da;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 -3px;
  }
`;

const CircleList = styled.div`
  /* remove defaults */
  padding: 0;
  margin: 0;

  box-shadow: 0 1px 10px 1px;
  background: radial-gradient(#323232, #070600);
  border-radius: 50%;
  transition: ease 0.5s;
  position: relative;
  display: inline-block;
  width: 35vw;
  height: 35vw;
  top: 3em;
  left: 25vw;
  animation-name: ${rotateAnimation};
  animation-duration: 2s;
  animation-iteration-count: 1;

  @media ${mediaQuery.mobile} {
    height: 86vw;
    width: 86vw;
    left: calc(50% - 43vw);
    animation-name: ${rotateAnimation};
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
`;

const Image = styled.img`
  position: absolute;
  height: 60vmin;
  z-index: 100;
  top: 12vmin;
  left: 12vw;

  @media ${mediaQuery.mobile} {
    height: 12rem;
    width: 12rem;
    transform: scaleX(-1) rotate(270deg);
    left: calc(50% - 6rem);
    top: 21rem;
  }
  @media ${mediaQuery.desktopSmall} {
    left: 2vw;
  }
`;

const WoodenBase = styled.div<{ src: string }>`
  position: absolute;
  margin: 0;
  height: 90%;
  width: 90vw;
  left: calc(50% - 45vw);
  top: calc(50% - 45%);

  @media ${mediaQuery.mobile} {
    height: 90%;
    width: 90vw;
    left: calc(50% - 45vw);
    top: calc(50% - 45%);
  }

  @media ${mediaQuery.desktopSmall} {
    height: 80%;
    width: 80vw;
    left: calc(50% - 40vw);
    top: calc(50% - 40%);
  }

  box-shadow: 0 1px 10px 1px;
  border-radius: 50px;
  background-color: #632402;
  background-image: url(${(props) => props.src});
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const links = [
  { route: routes.jukebox, title: "Jukebox" },
  { route: routes.songs, title: "Songs" },
  { route: routes.events, title: "Gear" },
  { route: routes.events, title: "Events" },
  { route: routes.events, title: "Contact" },
];

export const RotatingMenu = () => {
  const { loading, error, data } = useQuery(HomePageQuery);
  if (loading) return <div>loading...</div>;
  if (error) return <div>error loading data...</div>;
  if (data) {
    return (
      <Wrapper>
        <WoodenBase src={data.HomePage.backgroundImage.asset.url ?? ""}>
          <Image alt={"Record Player Needle"} src={image.src} />
          <CircleList>
            <MiddleDot />
            <nav>
              <ul>
                {links.map(({ route, title }, index) => {
                  return (
                    <CircleListItem key={`navlink-${index}`}>
                      <Link
                        href={route}
                        passHref
                        prefetch={false}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {title}
                      </Link>
                    </CircleListItem>
                  );
                })}
              </ul>
            </nav>
          </CircleList>
        </WoodenBase>
      </Wrapper>
    );
  }
  return <div>error...</div>;
};
